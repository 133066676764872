@use 'sass:math';
@import 'mixins';
@import 'variables';

.errorPage {
    //.errorPage__container
    &__container {
    }

    // .errorPage__box
    &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 24px;
    }

    // .errorPage__main
    &__main {
        max-width: 600px;

        position: relative;
        text-align: center;
        padding-bottom: 32px;

        display: flex;
        flex-direction: column;
        align-items: center;

        &:before {
            content: '';
            position: absolute;
            width: 360px;
            height: 1px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: var(--Bleached-Silk);
        }

        @media (max-width: ($mobile)) {
            padding-bottom: 24px;
        }
    }

    // .errorPage__logo
    &__logo {
        width: 256px;
        height: 256px;

        @media (max-width: ($mobile)) {
            width: 128px;
            height: 128px;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    // .errorPage__code
    &__code {
        font-family: $fontPlatformLC;
        font-size: 72px;
        font-weight: 500;
        line-height: 120%;

        &:not(:last-child) {
            margin-bottom: 12px;
            @media (max-width: ($mobile)) {
                margin-bottom: 8px;
            }
        }

        @media (max-width: ($mobile)) {
            font-size: 44px;
        }
    }

    // .errorPage__text
    &__text {
        font-size: 18px;
        line-height: 140%;

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        @media (max-width: ($mobile)) {
            font-size: 16px;
        }
    }
}
